export let TOKEN_KEY

// eslint-disable-next-line
if ($BUILD_TARGET$ === 'production') {
  TOKEN_KEY = 'hengrui-radiation-h5-pro'
} else {
  TOKEN_KEY = 'hengrui-radiation-h5-dev'
}

export const OSS_RESIZE_IMAGE_PARAMS = '?x-oss-process=style/resize'
