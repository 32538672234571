import { h, Component } from 'preact'
import {
  RowView,
  SlotColumnView,
  SpaceHolder,
} from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import WithRouter from '@xupright/preact-m-router'
import { ScrollerWithRefresh } from '@ruiyun/preact-m-scroller'
import p2r from 'p-to-r'
import Icon from '@ruiyun/preact-icon'

import WithUserInfo from '../../libs/withUserInfo'
import WithAppCategories from '../../libs/appCategories'
import { MEETING_TYPE_MAP } from '../../constants/meeting'

const ProjectItem = ({
  // myApplied,
  // mySettled,
  title,
  onGoList,
}) => (
  <RowView
    margin={[20, 30, 0, 20]}
    padding={[45, 30, 45, 30]}
    style={{ borderRadius: p2r(30) }}
    bgColor='#fff'
    onClick={onGoList}
    hAlign='between'
  >
    <SlotColumnView slot={16}>
      <Text color='#181818' size={34} style={{ fontWeight: 500 }}>
        {title}
      </Text>
      {/* <SlotRowView slot={0} vAlign='center'>
        <TextButton textColor='#888' textSize={26}>
          已申请{myApplied || 0}场，已结算{mySettled || 0}场
        </TextButton>
      </SlotRowView> */}
    </SlotColumnView>
    <Icon
      size={38}
      name='iconJIANTOU-small'
      color='#888888'
      style={{ marginTop: p2r(2) }}
    />
  </RowView>
)

@WithRouter
@WithUserInfo
@WithAppCategories
export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onGoApply = (code, e) => {
    e.stopPropagation()
    const path = MEETING_TYPE_MAP[code]?.applyPath
    path &&
      this.props.$router.push(path, {
        meetingType: code,
      })
  }

  onGoList = (code) => {
    const path = MEETING_TYPE_MAP[code]?.listPath
    path &&
      this.props.$router.push(path, {
        meetingType: code,
      })
  }

  componentDidMount() {
    this.props.$fetchCategories()
  }

  onRefresh = (done) => {
    setTimeout(() => {
      this.props.$fetchCategories().then(done)
    }, 300)
  }

  render() {
    return (
      <ScrollerWithRefresh onRefresh={this.onRefresh} minHeight='70vh'>
        {this.props.$CATEGORIES.map((item) => (
          <ProjectItem
            {...item}
            onGoApply={(e) => this.onGoApply(item.code, e)}
            onGoList={() => this.onGoList(item.code)}
            key={item.code}
          />
        ))}
        <SpaceHolder height={200} />
      </ScrollerWithRefresh>
    )
  }
}
