import { h } from 'preact'
import Text from '@ruiyun/preact-text'
import {
  ColumnView,
  RowView,
  SlotRowView,
  XCenterView,
} from '@ruiyun/preact-layout-suite'
import p2r from 'p-to-r'
import Button from '@ruiyun/preact-button'
import Icon from '@ruiyun/preact-icon'
import Image from '@ruiyun/preact-image'
import dayjs from 'dayjs'

import classNames from './app.css'
import { MEETING_STATUS_MAP, MEETING_TYPE_MAP } from '../../constants/meeting'
import { Tag } from '../../components/ApplyTimeTag'

const styleDefault = { bgColor: '#FFEEE5', color: '#E35513' }

const ButtonContent = ({ bgColor, title, color, img, size }) => {
  return size === 'big' ? (
    <RowView
      vAlign='center'
      hAlign='between'
      padding={[0, 20, 0, 30]}
      width={330}
      height={200}
      style={{
        borderRadius: p2r(30),
        backgroundColor: bgColor,
      }}
    >
      <Text size={36} color={color} style={{ fontWeight: 500 }}>
        {title}
      </Text>
      <img style={{ width: p2r(105), marginBottom: p2r(15) }} src={img} />
    </RowView>
  ) : (
    <ColumnView
      vAlign='between'
      hAlign='center'
      width={210}
      height={240}
      padding={[30, 0, 45, 0]}
      style={{
        borderRadius: p2r(30),
        backgroundColor: bgColor,
      }}
    >
      <img style={{ height: p2r(92) }} src={img} />
      <Text size={30} color={color} style={{ fontWeight: 500 }}>
        {title}
      </Text>
    </ColumnView>
  )
}

export const EntryButtons = ({ entries, onEntryClick }) => {
  // 目前最多同时有5个会议类型，每个会议类型入口按钮的样式参考以下规则：
  // n%3===0 用小样式、n%3 === 1 用大样式、n%3 === 2 最后两个大样式，其他小样式
  const getSize = (n, index) => {
    if (n % 3 === 1 || (n % 3 === 2 && (index === n - 1 || index === n - 2))) {
      return 'big'
    }
    return 'small'
  }

  return (
    <RowView
      hAlign='between'
      margin={[25, 30, 0, 30]}
      style={{ flexWrap: 'wrap' }}
    >
      {entries.map((item, index) => {
        const style = MEETING_TYPE_MAP[item.code]?.styleProps || styleDefault
        return (
          <Button
            key={index}
            borderColor='transparent'
            onClick={() => onEntryClick(item.code)}
            style={{ marginBottom: p2r(30) }}
          >
            <ButtonContent
              bgColor={style?.bgColor}
              title={item.title}
              color={style?.color}
              img={style?.img}
              size={getSize(entries?.length, index)}
            />
          </Button>
        )
      })}
    </RowView>
  )
}

export const ContentItem = ({
  title,
  source,
  createTimestamp,
  thumb,
  reviewed,
  signedUrl,
  previewUrl,
}) => (
  <RowView
    width='100%'
    hAlign='between'
    padding={[40, 30, 40, 30]}
    margin={[20, 0, 0, 0]}
    bgColor='#fff'
    style={{ borderRadius: p2r(30) }}
  >
    <ColumnView width='70%'>
      <Text
        size={34}
        className={classNames.ellipsis}
        style={{ fontWeight: 500, color: '#181818' }}
      >
        {title}
      </Text>
      <RowView margin={[14, 0, 14, 0]}>
        <Text
          size={28}
          color='#666'
          className={classNames.ellipsis}
          style={{ maxWidth: '45%' }}
        >
          {source}
        </Text>
        <RowView
          margin={[0, 20, 0, 20]}
          bgColor='#BABDC7'
          height={30}
          width={2}
        />
        <Text
          size={28}
          color='#666'
          className={classNames.ellipsis}
          style={{ maxWidth: '53%' }}
        >
          {createTimestamp ? dayjs(createTimestamp).format('YYYY.MM.DD') : '-'}
        </Text>
      </RowView>
      <SlotRowView slot={34}>
        <SlotRowView slot={10}>
          <Icon name='iconSEE' color='#BABDC7' size={28} />
          <Text color='#666'>{thumb || 0}</Text>
        </SlotRowView>
        <SlotRowView slot={10}>
          <Icon name='iconLIUYAN' color='#BABDC7' />
          <Text color='#666'>{reviewed || 0}</Text>
        </SlotRowView>
      </SlotRowView>
    </ColumnView>
    <Image
      src={previewUrl}
      height={160}
      width={160}
      mode='fill'
      style={{
        borderRadius: p2r(30),
      }}
    />
  </RowView>
)

export const TodoItem = ({
  meetingInfo: {
    activityType,
    meetingType,
    startTime,
    endTime,
    owner: { cnName },
    name,
    applyTime,
    id,
  },
  status,
  onEditMeeting,
  onMeetingItemClick,
  activeKey,
}) => (
  <ColumnView
    width='100%'
    padding={[40, 30, 40, 30]}
    margin={[20, 0, 0, 0]}
    bgColor='#fff'
    style={{ borderRadius: p2r(30) }}
  >
    <ColumnView onClick={() => onMeetingItemClick({ meetingType, id })}>
      <RowView hAlign='between'>
        <Text
          size={34}
          className={classNames.ellipsis}
          style={{ maxWidth: '75%', fontWeight: 500, color: '#181818' }}
        >
          {name}
        </Text>
        <RowView
          hAlign='center'
          vAlign='center'
          padding={[10, 12, 8, 14]}
          bgColor={
            activeKey === 1
              ? MEETING_STATUS_MAP.MEETING_IN_APPROVING.bgColor
              : MEETING_STATUS_MAP[status]?.bgColor || ''
          }
          style={{
            borderRadius: p2r(30),
            marginRight: `-${p2r(30)}`,
          }}
          className={classNames.borderLeft}
        >
          <Text
            size={24}
            color={
              activeKey === 1
                ? MEETING_STATUS_MAP.MEETING_IN_APPROVING.color
                : MEETING_STATUS_MAP[status]?.color || '#EE7700FF'
            }
            lineHeight='1.2'
          >
            {activeKey === 1
              ? '待审核'
              : `${MEETING_STATUS_MAP[status]?.name || ''}`}
          </Text>
        </RowView>
      </RowView>
      <RowView margin={[14, 0, 16, 0]}>
        <Text
          size={28}
          color='#666'
          className={classNames.ellipsis}
          style={{ maxWidth: '34%' }}
        >
          {cnName}
        </Text>
        <Text
          size={28}
          color='#BABDC7'
          className={classNames.ellipsis}
          style={{ padding: `0 ${p2r(10)} 0 ${p2r(10)}` }}
        >
          /
        </Text>
        <Text
          size={28}
          color='#666'
          className={classNames.ellipsis}
          style={{ maxWidth: '60%' }}
        >
          {dayjs(startTime).format('YYYY.MM.DD')}-
          {dayjs(endTime).format('YYYY.MM.DD')}
        </Text>
      </RowView>

      {applyTime && (
        <Tag
          padding={[0, 0, 0, 0]}
          fontColor='#BABDC7'
          bgColor='#fff'
          margin={[0, 0, 0, 0]}
          fontSize={28}
          text={`申请时间：${dayjs(applyTime).format('YYYY.MM.DD HH:mm')}`}
        />
      )}
    </ColumnView>

    {/* {activeKey === 1 ||
    ['MEETING_IN_PROGRESS', 'MEETING_RETREATED'].includes(status) ? (
      <XCenterView>
        <Button
          width={180}
          height={70}
          textColor='#EB9573'
          textSize={30}
          borderRadius={59}
          onClick={() => {
            if (activeKey === 1) {
              onMeetingItemClick({ meetingType, id })
            } else {
              onEditMeeting({ meetingType, id })
            }
          }}
          borderColor='rgba(235, 149, 115, 0.5)'
        >
          {activeKey === 1 ? '去审核' : '去编辑'}
        </Button>
      </XCenterView>
    ) : null} */}
  </ColumnView>
)
