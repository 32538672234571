import { QUERY_MY_APPROVING_MEETINGS } from '../constants/meeting.gql'
import { GraphQLClient } from '../libs/request'
export const queryMyApprovingMeetings = ({
  pageSize,
  pageNum,
  approveStatus,
}) =>
  GraphQLClient.gql(
    QUERY_MY_APPROVING_MEETINGS,
    {
      approveStatus,
      pageQuery: {
        pager: {
          currentPage: pageNum,
          pageSize,
        },
      },
    },
    false,
    'all'
  )
