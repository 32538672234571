/**
 * 自动轮播图
 **/
import { h, Component } from 'preact'
import { RowView, SlotRowView, XCenterView } from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import Swiper from '@ruiyun/preact-m-swiper'
import Image from '@ruiyun/preact-image'
import Loading from '@ruiyun/preact-loading'
import p2r from 'p-to-r'

import { useInterval, useTimeout } from '../../libs/useInterval'

export default class SwiperController extends Component {
  constructor(props) {
    super(props)
    // this.urls = props.urls
    this.state = { activeIndex: 0, auto: true }
  }

  onSwipe = (index) => {
    this.setState({
      activeIndex: index === this.props.urls.length ? 0 : index,
      auto: false,
    })
  }

  render() {
    const { activeIndex, auto } = this.state

    const urlsLength = this.props.urls.length

    // 默认 3s 自动滑动一次
    useInterval(
      () =>
        this.setState({
          activeIndex: activeIndex === urlsLength - 1 ? 0 : activeIndex + 1,
        }),
      auto && urlsLength ? 3000 : null
    )

    useTimeout(
      () => {
        this.setState({ auto: true })
      },
      !auto ? 1000 : null
    )

    return this.props.loading ? (
      <XCenterView height={304 + 20}>
        <Loading size={50} color='#999' />
      </XCenterView>
    ) : (
      <RowView
        height={304 + 20}
        style={{
          position: 'relative',
          margin: urlsLength ? 'unset' : `0 ${p2r(30)}`,
        }}
      >
        {urlsLength ? (
          <Swiper activeIndex={activeIndex} onChange={this.onSwipe}>
            {this.props.urls.map((url, index) => {
              return (
                <Image
                  key={`${index}_${url}`}
                  src={url}
                  height={304}
                  mode='fill'
                  style={{
                    margin: `${p2r(20)} ${p2r(30)}`,
                    borderRadius: p2r(30),
                  }}
                  onClick={() => {
                    console.log(url)
                  }}
                />
              )
            })}
          </Swiper>
        ) : (
          <XCenterView height={304} width='100%' bgColor='#eee'>
            <Text>图片为空</Text>
          </XCenterView>
        )}

        {urlsLength ? (
          <SlotRowView
            slot={10}
            width='100%'
            hAlign='center'
            style={{ position: 'absolute', bottom: p2r(50) }}
          >
            {new Array(this.props.urls.length).fill().map((i, index) => (
              <RowView
                key={index}
                width={index === activeIndex ? 20 : 10}
                height={10}
                style={{ borderRadius: p2r(8) }}
                bgColor={
                  index === activeIndex ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)'
                }
              />
            ))}
          </SlotRowView>
        ) : null}
      </RowView>
    )
  }
}
