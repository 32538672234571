/* eslint-disable max-lines */
import { h, Component, Fragment } from 'preact'
import {
  ColumnView,
  RowView,
  SlotRowView,
  SlotColumnView,
  XCenterView,
} from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import Icon from '@ruiyun/preact-icon'
import Line from '@ruiyun/preact-line'
import { TextButton } from '@ruiyun/preact-button'
import { ScrollerWithRefresh } from '@ruiyun/preact-m-scroller'
import WithRouter from '@xupright/preact-m-router'
import p2r from 'p-to-r'
import wx from 'weixin-js-sdk'

import ItemCell from '../../components/ItemCell'
import { getCurrentUserInfo } from '../../services/UserService'
import WithUserInfo from '../../libs/withUserInfo'
import { WithModal } from '@ruiyun/preact-modal'
import HeadImgSvg from '../../assets/headTitleImg.svg'

const UserOverview = ({ name, region, company, phone, department }) => {
  return (
    <SlotRowView slot={30} padding={[0, 0, 0, 30]}>
      <RowView
        hAlign='center'
        height={130}
        width={130}
        style={{ borderRadius: '50%' }}
        // bgColor='#fff'
      >
        <img style={{ width: p2r(130), height: p2r(130) }} src={HeadImgSvg} />
        {/* <Icon name='iconWODE' size={72} color='#ccc' /> */}
      </RowView>
      <SlotColumnView vAlign='center' style={{ flex: 1 }} slot={8}>
        <Text size={40} color='#4C5189' style={{ fontWeight: 500 }}>
          {name}
        </Text>
        <RowView style={{ flexWrap: 'wrap' }}>
          <Text size={28} color='#4C5189'>
            {department}
          </Text>
          <RowView
            margin={[0, 20, 0, 20]}
            bgColor='rgb(76,81,137,20%)'
            height={30}
            width={2}
          />
          <Text size={28} color='#4C5189'>
            {phone}
          </Text>
        </RowView>
      </SlotColumnView>
    </SlotRowView>
  )
}
@WithRouter
@WithUserInfo
@WithModal
export default class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: '',
      name: '',
      region: '',
      company: '',
      meetingOnline: { applyCount: 0 },
      meetingOffline: { applyCount: 0 },
    }
  }

  queryUserInfo = async (isLoading = true) => {
    const { success, data } = await getCurrentUserInfo(isLoading)
    if (success) {
      this.setState({
        accountId: data.accountId,
        name: data.cnName,
        region: data.region?.name,
        company: data.company.cnName,
        phone: data.companyMobile,
        email: data?.companyEmail,
        department: data?.department?.name,
        products: [data?.product?.name],
        reportAccount: data?.reportAccount,
        budgetInfo: data?.budgetInfo,
        ownedProductions: data?.ownedProductions || [],
      })
    }
  }

  onProductsModalShow = () => {
    this.props.$modal.show({
      allowContentTouchMove: true,
      content: () => (
        <SlotColumnView
          slot={12}
          bgColor='#ffffff'
          padding={[40, 30, 40, 30]}
          width={650}
          hAlign='center'
          style={{
            borderRadius: p2r(6),
            maxHeight: p2r(800),
            overflow: 'auto',
          }}
        >
          {this.state.ownedProductions?.map((item, index) => (
            <Text key={index} size={30}>
              {item.title}
            </Text>
          ))}
        </SlotColumnView>
      ),
      position: 'center',
    })
  }

  onRefresh = (done) => {
    setTimeout(() => {
      this.queryUserInfo(false).then(done)
    }, 300)
  }

  onActivityClick = () => {
    this.props.$router.push('activityList')
  }

  onCountClick = () => {
    this.props.$router.push('meetingList', {
      meetingType: 'ACADEMIC_CONFERENCE',
    })
  }

  logout = () => {
    wx.miniProgram.navigateTo({
      url: `/pages/Logout/index`,
    })
  }

  async componentDidMount() {
    this.queryUserInfo()
  }

  render() {
    const {
      name,
      phone,
      email,
      department,
      ownedProductions,
      reportAccount,
      budgetInfo,
    } = this.state

    return (
      <ScrollerWithRefresh onRefresh={this.onRefresh} minHeight='70vh'>
        <SlotColumnView
          slot={58}
          padding={[85, 30, 60, 30]}
          style={{
            background: 'linear-gradient(180deg, #F8E0FE 0%, #C1DAFF 100%)',
          }}
        >
          <UserOverview {...this.state} />

          {/* <SlotRowView slot={30}>
              {MEETING_TYPES.map((item, index) => {
                return (
                  <CountItem
                    key={index}
                    item={item}
                    {...this.state[item.type]}
                    onItemClick={this.onCountClick}
                  />
                )
              })}
            </SlotRowView> */}
        </SlotColumnView>

        {/* <ActivityMenuList onItemClick={this.onActivityClick} /> */}

        <ColumnView
          bgColor='#fff'
          margin={[20, 30, 0, 30]}
          padding={[10, 0, 10, 0]}
          style={{ borderRadius: p2r(30) }}
        >
          <ItemCell textSize={34} label='个人信息' textColor='#999999' />
          <ItemCell label='姓名' value={name} />
          <ItemCell label='手机' value={phone} />
          <ItemCell label='邮箱' value={email} />
          <ItemCell label='部门' value={department} />
          <ItemCell
            label='负责产品'
            arrow={ownedProductions?.length > 1}
            value={ownedProductions?.[0]?.title}
            labelColor='#181818'
            line={reportAccount && !this.props.$IS_QILU}
            onClick={ownedProductions?.length > 1 && this.onProductsModalShow}
          />

          {reportAccount && !this.props.$IS_QILU && (
            <Fragment>
              <ItemCell
                line={false}
                label='直属上级'
                slot={() => (
                  <SlotRowView slot={20} height={42}>
                    <Text color='#666'>{reportAccount.cnName}</Text>
                    <Line v color='#ccc' indent={[5, 5]} />
                    <Text color='#666'>{reportAccount.companyMobile}</Text>
                  </SlotRowView>
                )}
              />
            </Fragment>
          )}
        </ColumnView>

        {this.props.$IS_HANSOH ? (
          <ColumnView
            bgColor='#fff'
            margin={[20, 30, 0, 30]}
            padding={[10, 0, 10, 0]}
            style={{ borderRadius: p2r(30) }}
          >
            <ItemCell textSize={34} label='预算信息' textColor='#999999' />
            <ItemCell
              label='本月总预算'
              value={budgetInfo?.monthBudget || '-'}
            />
            <ItemCell label='本月使用' value={budgetInfo?.usedAmount || '-'} />
            <ItemCell
              label='本月结余'
              value={budgetInfo?.balanceAmount || '-'}
              line={false}
            />
          </ColumnView>
        ) : null}

        {/* 邀请讲师入口去掉 */}
        {/* <ColumnView
          bgColor='#fff'
          margin={[20, 30, 20, 30]}
          padding={[10, 0, 10, 0]}
          style={{ borderRadius: p2r(30) }}
        >
          <ItemCell
            line={false}
            arrow。
            label='邀请老师'
            onClick={() => {
              this.props.$router.push('doctorSearch', {
                mode: 'inviteWithoutMeeting',
              })
            }}
          />
        </ColumnView> */}

        <XCenterView height={400}>
          <TextButton textSize={28} textColor='#ccc' onClick={this.logout}>
            退出登录
          </TextButton>
        </XCenterView>
      </ScrollerWithRefresh>
    )
  }
}
