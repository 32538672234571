let BASE_URL
let BASE_GRAPHQL_URL
let STS_SERVICE_URL
let BASE_PROCESS_URL
let BASE_DOCAUTH_URL
// eslint-disable-next-line
if ($BUILD_TARGET$ === 'production') {
  BASE_URL = ''
  BASE_GRAPHQL_URL = 'https://uplatform-graphql.yihuili.net'
  STS_SERVICE_URL = 'https://faas.yihuili.net'
  BASE_PROCESS_URL = '/conference-api/'
  BASE_DOCAUTH_URL = 'https://uplatform-graphql.yihuili.net'
  // eslint-disable-next-line
} else if ($BUILD_TARGET$ === 'dev') {
  BASE_URL = 'https://uapi.dev.quancheng-ec.com/baey'
  BASE_GRAPHQL_URL = 'https://uplatform-graphql.dev.yihuili.net'
  STS_SERVICE_URL = 'https://faas.yihuili.net'
  BASE_PROCESS_URL = '/conference-api/'
  BASE_DOCAUTH_URL = 'https://uplatform-graphql.dev.yihuili.net'
} else {
  BASE_URL = 'https://uapi.dev.quancheng-ec.com/baey'
  BASE_GRAPHQL_URL = 'https://uplatform-graphql.dev.yihuili.net'
  STS_SERVICE_URL = 'https://faas.yihuili.net'
  BASE_PROCESS_URL =
    'https://interventional-project.upapp.quancheng-ec.com/conference-api/'
  BASE_DOCAUTH_URL = 'https://wx-open.quancheng-ec.com/daily'
}

export const GET_STS_URL = STS_SERVICE_URL + '/sts-token'
export const MEETING_URL = BASE_URL + '/meetings'
export const GRAPHQL_URL = BASE_GRAPHQL_URL + '/graphql'
export const FETCH_CAR_ORDER_TRACE = BASE_URL + '/api/travel/order/track'
export const SHORT_URL_SERVICE = 'https://s.yihuili.net/generate'
export const PROCESS_URL = BASE_PROCESS_URL
export const DOCAUTH_URL = BASE_DOCAUTH_URL
