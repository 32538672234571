import Ajax from '@ruiyun/ajax'
import { GRAPHQL_URL } from '../constants/apis'

export default class RequestClient {
  constructor(formatFn, gqlBaseUrl) {
    this.formatFn = formatFn
    this.gqlBaseUrl = gqlBaseUrl
  }

  make = (method) => async (url, params, loading, catchErr) => {
    try {
      const ret = await Ajax[method](url, params, {
        headers: {
          loading: loading === false ? 'false' : 'true',
          catch: catchErr,
        },
      })
      return this.formatFn(ret)
    } catch (error) {
      return {
        success: false,
        error,
      }
    }
  }

  get = this.make('get')

  post = this.make('post')

  gql = async (query, params, loading, catchErr) => {
    try {
      const ret = await Ajax.post(
        this.gqlBaseUrl,
        {
          query,
          variables: params,
        },
        {
          headers: {
            loading: loading === false ? 'false' : 'true',
            catch: catchErr,
          },
        }
      )
      return this.formatFn(ret)
    } catch (error) {
      return {
        success: false,
        error,
      }
    }
  }
}

export const JavaRequestClient = new RequestClient((ret) => {
  let response = { success: false }
  if (ret.success) {
    response = {
      success: true,
      data: ret.result,
    }
  } else {
    response.error = ret.message
    response.data = ret.result
  }
  return response
})

export const GraphQLClient = new RequestClient((ret) => {
  let response = { success: false }
  if (ret.errors && ret.errors.length) {
    response.error = ret.errors[0]
  } else {
    const { result } = ret.data
    if (result !== null || result !== undefined) {
      response = {
        success: true,
        data: result,
      }
    } else {
      response.error = '未获取到数据'
    }
  }
  return response
}, GRAPHQL_URL)
