import { router } from '@xupright/preact-m-router'
import { TOKEN_KEY } from '../constants/other'
import { GraphQLClient } from '../libs/request'

import {
  SEND_CODE,
  LOGIN,
  CURRENT_USER,
  QUERY_COST_CENTER,
  NORMAL_SEND_CODE,
  NORMAL_VALIDATE_CODE,
  CHECK_OWNER_LIMIT,
  CHECK_ADD_DOCTOR_LIMIT,
  CHECK_PERSONAL_BUDGET,
  QUERY_USER_BUDGET,
} from '../constants/user.gql'

export const getCAPTCHA = (phone) =>
  GraphQLClient.gql(SEND_CODE, { mobile: phone }, false, 'all')
export const getNormalCAPTCHA = (phone) =>
  GraphQLClient.gql(NORMAL_SEND_CODE, { mobile: phone }, false, 'all')
export const validateNormalCAPTCHA = ({ phone, code, sessionId }) =>
  GraphQLClient.gql(NORMAL_VALIDATE_CODE, {
    mobile: phone + '',
    code: code + '',
    sessionId,
  })

export const login = ({ phone, code, sessionId }) =>
  GraphQLClient.gql(LOGIN, {
    mobile: phone + '',
    code: code + '',
    sessionId,
  })

export const logout = () => {
  window.localStorage.setItem(TOKEN_KEY, '')
  router.replace('login')
}

export const getCurrentUserInfo = (isLoading) =>
  GraphQLClient.gql(CURRENT_USER, {}, isLoading)
export const getCurrentUserCostCenter = () =>
  GraphQLClient.gql(QUERY_COST_CENTER)
export const checkOwnerLimit = ({ meetingId, meetingDate }) =>
  GraphQLClient.gql(CHECK_OWNER_LIMIT, { meetingId, meetingDate })
export const checkAddDoctorLimit = ({ budget, meetingId }) =>
  GraphQLClient.gql(CHECK_ADD_DOCTOR_LIMIT, { budget, meetingId })

// 用户即代表预算检查
export const checkPersonalBudget = ({ meetingId, amountToAdd }) =>
  GraphQLClient.gql(CHECK_PERSONAL_BUDGET, { meetingId, amountToAdd })

export const getUserBudget = () => GraphQLClient.gql(QUERY_USER_BUDGET)
