// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".shadowTop__rh6qj{z-index:1;-webkit-box-shadow:0 -2px 4px 0 hsla(0,0%,80%,.2);box-shadow:0 -2px 4px 0 hsla(0,0%,80%,.2)}.safeBottom__Xv2Z9{margin-bottom:env(safe-area-inset-bottom)}.footerPlaceHolder__T8zHU{width:100%;position:fixed;bottom:0;height:env(safe-area-inset-bottom)}", ""]);
// Exports
exports.locals = {
	"shadowTop": "shadowTop__rh6qj",
	"safeBottom": "safeBottom__Xv2Z9",
	"footerPlaceHolder": "footerPlaceHolder__T8zHU"
};
module.exports = exports;
