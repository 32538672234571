export const AccountInfoFragment = `
fragment AccountInfo on AccountInfo {
  accountId
  cnName
  companyMobile
  companyEmail
  department {
    name
  }
  region: group(groupType: "region") {
    name
    groupId
  }
  sector: group(groupType: "sector") {
    name
    groupId
  }
  product: group(groupType: "product") {
    name
    groupId
  }
  title: group(groupType: "title") {
    name
    groupId
  }
  reportAccount {
    cnName
    companyMobile
  }
  shangshuibaoRegistered
}
`
export const SEND_CODE = `mutation($mobile: String!) {
  result: hengruiImage_sendCode(
    mobile: $mobile
  )
}`

export const LOGIN = `mutation($mobile: String!, $code: String!, $sessionId: String!) {
  result: hengruiImage_loginWithCode(
    mobile: $mobile
    verifyCode: $code,
    sessionId: $sessionId
  )
}`
export const CURRENT_USER = `
${AccountInfoFragment}
query {
  result: currentUser {
    ...AccountInfo
    company{
      cnName
    }
    hrProduction
    budgetInfo{
      monthBudget
      usedAmount
      balanceAmount
    }
    ownedProductions{
      id
      title
      children{
        id
        title
      }
    }
  }
}`
export const QUERY_CITIES = `query {
  result: cities {
    id
    name
    nameEn
    initialEn
    pid
  }
}
`
export const QUERY_COST_CENTER = `
query queryUserCostCenter{
  result: hengruiImage_userCostCenter{
    id
    name
  }
}`

export const NORMAL_SEND_CODE = `mutation($mobile: String!) {
  result: normalSendCode(
    mobile: $mobile
  )
}`

export const NORMAL_VALIDATE_CODE = `mutation($mobile: String!, $code: String!, $sessionId: String!) {
  result: normalValidateCode(
    mobile: $mobile
    code: $code,
    sessionId: $sessionId
  )
}`

export const CHECK_OWNER_LIMIT = `
query queryOwnerLimit($meetingId: String, $meetingDate: Timestamp!){
  result: hengruiImage_checkOwnerLimit(meetingId:$meetingId, meetingDate:$meetingDate){
    limitReached
    limitEnabled
    message
  }
}`

export const CHECK_ADD_DOCTOR_LIMIT = `
query queryAddDoctorLimit($budget: Float, $meetingId: String!){
  result: hengruiImage_checkAddDoctorLimit(budget: $budget, meetingId: $meetingId){
    limitReached
    limitEnabled
    message
  }
}`

export const CHECK_PERSONAL_BUDGET = `
query($meetingId: String!, $amountToAdd: Float) {
  result: checkPersonalBudget(meetingId: $meetingId, amountToAdd: $amountToAdd){
    limitReached
    limitEnabled
    message
  }
}
`

export const QUERY_USER_BUDGET = `
query {
  result: currentUser {
    budgetInfo{
      monthBudget
      usedAmount
      balanceAmount
    }
  }
}
`
