import { setIconFontUrl } from '@ruiyun/preact-icon'
import Indicator from 'h5-indicator'
import {
  setDefaultErrorHander,
  setLoadingMethod,
  setOnRequestHandler,
} from '@ruiyun/ajax'
import wx from 'weixin-js-sdk'

import { TOKEN_KEY } from './constants/other'

export default function onEntry() {
  // 登录功能不在该项目中，此项目通过首页路由中的参数 token 获取用户登录权限
  // 启动后，请访问 http://{本机ip}:3000?token={用户token}
  // token 获取方式：代表端小程序微信授权登录后，抓包获取 curl 中的 token 信息
  // 例如：http://10.100.0.108:3003/?token=qcsf:103210219170816001:baa16cbc
  document.querySelector('body').style.fontFamily = 'PingFang SC'
  const isInvitationPage = window.location.href.includes('/invitation.html')
  const isInvitationActivityPage = window.location.href.includes(
    '/invitationActivity.html'
  )
  const isRegisterPage =
    window.location.href.includes('/registerDoctorInfo.html') ||
    window.location.href.includes('/hcpRegisterDoctorInfo.html') ||
    window.location.href.includes('/invitationDoctor.html')
  const hasRegistered = window.location.href.includes('/hasRegistered.html')
  const isAgreement = window.location.href.includes('/agreement.html')
  const hasToken = window.localStorage.getItem(TOKEN_KEY)

  const isErrorPage = window.location.href.includes('/error.html')
  const isSignSuccess = window.location.href.includes('./signSuccess.html')

  if (
    !hasToken &&
    !isRegisterPage &&
    !isInvitationPage &&
    !isInvitationActivityPage &&
    !hasRegistered &&
    !isAgreement &&
    !isErrorPage &&
    !isSignSuccess
  ) {
    // router.replace('error')
    wx.miniProgram.navigateTo({
      url: `/pages/Logout/index`,
    })
  }

  // 下面的代码每个页面初始化时都会执行
  setIconFontUrl('//at.alicdn.com/t/c/font_2717091_2kic397xcyj.css')
  // 下面的代码每个页面初始化时都会执行.
  setLoadingMethod({
    show: Indicator.showLoading,
    hide: Indicator.hideLoading,
  })
  setDefaultErrorHander((error) => {
    if (error.response) {
      const status = error.response.status
      if (status === 401) {
        // 未授权
        // 删除本地无效token
        window.localStorage.setItem(TOKEN_KEY, '')
        Indicator.toast('授权过期')
        // 跳转登录页面
        // router.replace('error')
        wx.miniProgram.navigateTo({
          url: `/pages/Logout/index`,
        })
      } else if (status < 500) {
        Indicator.toast(error.response.data.message)
      } else {
        Indicator.toast('服务异常,请稍后再试')
      }
    } else {
      Indicator.toast('网络异常')
    }
  })
  setOnRequestHandler((config) => {
    const token = window.localStorage.getItem(TOKEN_KEY)
    if (token) {
      config.headers.token = token
    }
  })
}
