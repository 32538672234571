/* eslint-disable max-lines */
// 存在显示不出icon的情况，所以不可缺少的图标用图片代替
import Entry1 from '../assets/entry1.svg'
import Entry2 from '../assets/entry2.svg'
import Entry3 from '../assets/entry3.svg'
import Entry4 from '../assets/entry4.svg'
import Entry5 from '../assets/entry5.svg'
import Entry6 from '../assets/entry6.svg'
import Entry7 from '../assets/entry7.svg'
import Entry8 from '../assets/entry8.svg'
import Entry9 from '../assets/entry9.svg'
import Entry10 from '../assets/entry10.svg'
import AttentionGreen from '../assets/attentionGreen.svg'
import AttentionRed from '../assets/attentionRed.svg'
import AttentionYellow from '../assets/attentionYellow.svg'

export const MEETING_STATUS_MAP = {
  MEETING_IN_PROGRESS: {
    name: '执行中',
    value: 'MEETING_IN_PROGRESS',
    color: '#5581FA',
    bgColor: 'rgba(85, 129, 250, 0.1)',
  },
  MEETING_IN_APPROVING: {
    name: '审批中',
    value: 'MEETING_IN_APPROVING',
    color: '#FF9847',
    bgColor: 'rgba(255, 152, 71, 0.1)',
  },
  MEETING_IN_SETTLEMENT: {
    name: '结算中',
    value: 'MEETING_IN_SETTLEMENT',
    color: '#5581FA',
    bgColor: 'rgba(85, 129, 250, 0.1)',
  },
  MEETING_RETREATED: {
    name: '审批退回',
    value: 'MEETING_RETREATED',
    color: '#F76060',
    bgColor: 'rgba(247, 96, 96, 0.1)',
  },
  MEETING_FINISHED: {
    name: '已完成',
    value: 'MEETING_FINISHED',
    color: '#999999',
    bgColor: 'rgba(153, 153, 153, 0.1)',
  },
  MEETING_SETTLEMENT_WITHOUT_TAX: {
    name: '已结算',
    value: 'MEETING_SETTLEMENT_WITHOUT_TAX',
    color: '#51A04C',
    bgColor: 'rgba(81, 160, 76, 0.1)',
  },
  MEETING_SETTLEMENT_CONFIRMING: {
    name: '结算确认中',
    value: 'MEETING_SETTLEMENT_CONFIRMING',
    color: '#FF9847',
    bgColor: 'rgba(255, 152, 71, 0.1)',
  },
}

// 处于结算确认中之后的会议状态
export const MEETING_STATUS_AFTER_SETTLEMENT_CONFIRMING = [
  'MEETING_IN_SETTLEMENT',
  'MEETING_FINISHED',
  'MEETING_SETTLEMENT_WITHOUT_TAX',
]

// 结算状态
export const SETTLEMENT_STATUS_MAP = {
  pending: {
    value: 'pending',
    name: '打款中',
    color: '#5680FA',
    bgColor: 'rgba(86, 128, 250, 0.1)',
  },
  unpaid: {
    value: 'unpaid',
    name: '等待打款',
    color: '#EE7700',
    bgColor: 'rgba(238, 119, 0, 0.1)',
  },
  succeed: {
    value: 'succeed',
    name: '打款成功',
    color: '#51A04C',
    bgColor: 'rgba(81, 160, 76, 0.1)',
    img: AttentionGreen,
    title: '付款信息',
  },
  failed: {
    value: 'failed',
    name: '打款失败',
    color: '#F76060',
    bgColor: 'rgba(247, 96, 96, 0.1)',
    img: AttentionRed,
    title: '打款失败',
  },
  delay: {
    value: 'delay',
    name: '延迟打款',
    color: '#EE7700',
    bgColor: 'rgba(238, 119, 0, 0.1)',
    img: AttentionYellow,
    title: '延迟打款',
  },
}

export const MEETING_APPROVAL_STATUS_MAP = {
  APPROVING: {
    name: '审批中',
    value: 'APPROVING',
  },
  NOT_APPROVAL: {
    name: '未审批',
    value: 'NOT_APPROVAL',
  },
  SUCCESS: {
    name: '审批通过',
    value: 'SUCCESS',
  },
  FAIL: {
    name: '审批未通过',
    value: 'FAIL',
  },
  RETREAT: {
    name: '已退回',
    value: 'RETREAT',
  },
  WITHDRAW: {
    name: '已撤回',
    value: 'WITHDRAW',
  },
}

// 下面的会议类型的属性尚未用后台配置，所以在这里手写了一套
export const MEETING_TYPE_MAP = {
  ACADEMIC_CONFERENCE: {
    listPath: 'meetingList',
    applyPath: 'meetingEntry',
    detailPath: 'meetingDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      meetingId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#FDE8F2', color: '#EC522B', img: Entry1 },
    typeName: '会议',
    typeBgColor: 'rgb(236,82,43, 0.1)',
    typeFontColor: '#EC522B',
  },
  ADVERTISING_ACTIVITIES: {
    listPath: 'activityList',
    applyPath: 'activityEntry',
    detailPath: 'activityDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      activityId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#FFEEE5', color: '#E35513', img: Entry2 },
    typeName: '活动',
    typeBgColor: 'rgb(255,152,71, 0.1)',
    typeFontColor: '#FF9847',
  },
  MEDICAL_RESEARCH_PROJECT: {
    listPath: 'projectList',
    applyPath: 'applyProject',
    detailPath: 'projectDetail',
    speakersField: 'doctors',
    formatParams: (item) => ({
      projectId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#E1F9FF', color: '#38C4EE', img: Entry5 },
    typeName: '项目',
  },
  NORTHERN_FUJIAN_CASE: {
    listPath: 'activityList',
    applyPath: 'applyActivity',
    detailPath: 'activityDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      activityId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#EEE5FF', color: '#C65EDB', img: Entry3 },
    typeName: '活动',
  },
  JIANGXI_CASE: {
    listPath: 'activityList',
    applyPath: 'applyActivity',
    detailPath: 'activityDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      activityId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#EBEEFF', color: '#7084F1', img: Entry4 },
    typeName: '活动',
  },
  CATE_CASE_COLLECTION: {
    listPath: 'activityList',
    applyPath: 'applyActivity',
    detailPath: 'activityDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      activityId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#E8F3FD', color: '#388CEE', img: Entry6 },
    typeName: '活动',
    typeBgColor: 'rgb(56,140,238, 0.1)',
    typeFontColor: '#388CEE',
  },
  CATE_QUESTIONNAIRE_RESEARCH: {
    listPath: 'activityList',
    applyPath: 'applyActivity',
    detailPath: 'activityDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      activityId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#DCFBE3', color: '#03C02A', img: Entry7 },
    typeName: '活动',
    typeBgColor: 'rgb(3,192,42, 0.1)',
    typeFontColor: '#03C02A',
  },
  CATE_DOCUMENT_COMMENT: {
    listPath: 'activityList',
    applyPath: 'applyActivity',
    detailPath: 'activityDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      activityId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#EBEEFF', color: '#7084F1', img: Entry8 },
    typeName: '活动',
    typeBgColor: 'rgb(112,132,241, 0.1)',
    typeFontColor: '#7084F1',
  },
  INTERVENTION_SPECIAL_LINE_PROJECT: {
    listPath: 'meetingList',
    applyPath: 'applyMeeting',
    detailPath: 'meetingDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      meetingId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#FFF1D9', color: '#E79711', img: Entry9 },
    typeName: '活动',
  },
  CATE_DOCUMENT_COMPOSE: {
    listPath: 'activityList',
    applyPath: 'applyActivity',
    detailPath: 'activityDetail',
    speakersField: 'speakers',
    formatParams: (item) => ({
      activityId: item.meetingInfo.id,
    }),
    styleProps: { bgColor: '#FFF1D9', color: '#E79711', img: Entry10 },
    typeName: '活动',
  },
}

export const MEETING_DOCTOR_INVITE_STATUS_MAP = {
  COMPLETE: {
    name: '已完成',
    value: 'COMPLETE',
  },
  SENT: {
    name: '已邀请',
    value: 'SENT',
  },
  UNINVITE: {
    name: '待邀请',
    value: 'UNINVITE',
  },
  RECEIVED: {
    name: '已邀请',
    value: 'RECEIVED',
  },
}

export const BUDGET_TYPE_ARRAY = [
  {
    name: '会场费',
    value: 'site',
  },
  {
    name: '住宿费',
    value: 'room',
  },
  {
    name: '餐饮费',
    value: 'food',
  },
  {
    name: '交通费',
    value: 'traffic',
  },
  {
    name: '其他费用',
    value: 'other',
  },
]

export const DOCTOR_REGISTER_USEABLE_BANKS = [
  { name: '工商银行', code: 'ICBC' },
  { name: '农业银行', code: 'ABC' },
  { name: '中国银行', code: 'BOC' },
  { name: '建设银行', code: 'CCB' },
  { name: '交通银行', code: 'COMM' },
  { name: '中信银行', code: 'CITIC' },
  { name: '光大银行', code: 'CEB' },
  { name: '华夏银行', code: 'HXBANK' },
  { name: '民生银行', code: 'CMBC' },
  { name: '平安银行', code: 'SPABANK' },
  { name: '招商银行', code: 'CMB' },
  { name: '兴业银行', code: 'CIB' },
  { name: '北京银行', code: 'BJBANK' },
  { name: '浦发银行', code: 'SPDB' },
  { name: '邮储银行', code: 'PSBC' },
]
export const DOCTOR_SIGN_CHANNEL = {
  qkk: {
    name: '商税宝',
    code: 'QKK',
  },
  ssq: {
    name: '上上签',
    code: 'SSQ',
  },
  csy: {
    name: '财税云',
    code: 'CSY',
  },
  ptx: {
    name: '四川临税',
    code: 'PTX',
  },
  fg: {
    name: '上海复歌',
    code: 'FG',
  },
  HNJC: {
    name: '湖南金财',
    code: 'HNJC',
  },
  AXB: {
    name: '爱薪保',
    code: 'AXB',
  },
  HNMX: {
    name: '湖南茂新',
    code: 'HNMX',
  },
  HNLD: {
    name: '湖南灵达',
    code: 'HNLD',
  },
  HNSM: {
    name: '湖南税猫',
    code: 'HNSM',
  },
  HNDX: {
    name: '河南大熊',
    code: 'HNDX',
  },
  SHPT: {
    name: '上海平台',
    code: 'SHPT',
  },
  SDTX: {
    name: '山东天心',
    code: 'SDTX',
  },
  YHL: {
    name: '易惠利',
    code: 'YHL',
  },
}

export const JUNZIQIAN_GROUP_SIGN_CHANNELS = {
  fg: {
    name: '上海复歌',
    code: 'FG',
  },
  HNJC: {
    name: '湖南金财',
    code: 'HNJC',
  },
  AXB: {
    name: '爱薪保',
    code: 'AXB',
  },
  HNMX: {
    name: '湖南茂新',
    code: 'HNMX',
  },
  HNLD: {
    name: '湖南灵达',
    code: 'HNLD',
  },
  HNSM: {
    name: '湖南税猫',
    code: 'HNSM',
  },
  HNDX: {
    name: '河南大熊',
    code: 'HNDX',
  },
  SHPT: {
    name: '上海平台',
    code: 'SHPT',
  },
  SDTX: {
    name: '山东天心',
    code: 'SDTX',
  },
  YHL: {
    name: '易惠利',
    code: 'YHL',
  },
}

export const BASIC_TYPE_MAP = {
  meeting: '会议',
  activity: '活动',
  project: '项目',
}

export const MEETING_FLOW_CHART_PROMPT_INFO = {
  data: [
    ['新建会议', '邀请老师', '执行会议'],
    ['归档材料', '打款完税', '数据分析'],
  ],
  description: [
    '1、学术研讨活动组织执行，仅限于学术类专业观点分享、交流、研讨。不得以商业推广或者品牌推荐为目的。',
    '2、活动邀请执行，必须获得专业老师正式签约，并知晓活动目的与详情。',
    '3、活动材料需翔实记录，确保活动真实有效，履约文件准确、真实、齐全，包括并不限于日程安排、活动录屏录音、课题观点素材等。',
    '4、学术、合规等多级审验活动真实性与学术专业性，不得与商品及品牌推销、推荐相关。',
    '5、符合市场公允价的付费，以及合法缴税。',
  ],
}

export const BUDGET_TYPE_MAP = [
  { text: '默认', value: 0 },
  { text: '个人预算', value: 1 },
  { text: '流程预算', value: 2 },
]

// 使用医生任务的公司
export const DOCTOR_TASK_COMPANY_GROUP = [
  { name: '恒瑞麻醉市场', code: 'ANESTHESIOLOGY_DOC' },
  { name: '江苏豪森医药集团', code: 'HANSOH' },
]

// 任务相关的会议类型
export const DOCTOR_TASK_MEETING_TYPE = [
  'CATE_CASE_COLLECTION',
  'CATE_QUESTIONNAIRE_RESEARCH',
  'CATE_DOCUMENT_COMMENT',
  'CATE_DOCUMENT_COMPOSE',
]
