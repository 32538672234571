import { h } from 'preact'
import Text from '@ruiyun/preact-text'
import { RowView } from '@ruiyun/preact-layout-suite'
import dayjs from 'dayjs'
import p2r from 'p-to-r'
export const Tag = ({
  text,
  margin,
  bgColor = '#f4f4f4',
  fontSize = 24,
  fontColor = '#999999',
  style = {},
  textStyle = {},
  padding = [0, 5, 0, 5],
}) => (
  <RowView
    margin={margin}
    padding={padding}
    width='fit-content'
    bgColor={bgColor}
    style={Object.assign({ borderRadius: p2r(24) }, style)}
  >
    <Text style={textStyle} size={fontSize} color={fontColor}>
      {text}
    </Text>
  </RowView>
)
const ApplyTimeTag = ({ time }) => (
  <Tag text={`申请时间：${dayjs(time).format('YYYY.MM.DD')}`} />
)

export default ApplyTimeTag
