import { h, Component, Fragment } from 'preact'
import TabsSimple from '@ruiyun/preact-m-tabs-simple'
import AutoList from '@ruiyun/preact-m-auto-list'
import {
  SlotColumnView,
  RowView,
  ColumnView,
} from '@ruiyun/preact-layout-suite'
import WithRouter from '@xupright/preact-m-router'
import Line from '@ruiyun/preact-line'
import Button from '@ruiyun/preact-button'
import Text from '@ruiyun/preact-text'

import { queryMyApprovingMeetings } from '../../services/ApprovalService'
import MeetingBaseItem from '../../components/MeetingItem'
import EmptyView from '../../components/EmptyView'
import { MEETING_TYPE_MAP } from '../../constants/meeting'

const TAB_OPTIONS = ['待审核', '已审核']

const MeetingItem = ({
  meetingInfo,
  showApprovalBtn,
  onItemClick,
  approveTabText,
  ...others
}) => {
  const totalSpeakers =
    others[MEETING_TYPE_MAP[meetingInfo.meetingType]?.speakersField] || []

  return (
    <SlotColumnView onClick={() => onItemClick(meetingInfo)}>
      <MeetingBaseItem
        info={meetingInfo}
        {...others}
        speakers={totalSpeakers}
        approveTabText={approveTabText}
      />
    </SlotColumnView>
  )
}
@WithRouter
export default class MeetingList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
    }
  }

  // 复杂的keyExtractor来保证item显示字段有更新的话就updateItem
  keyExtractor = ({
    meetingInfo: {
      id,
      startTime,
      endTime,
      owner: { cnName },
      name,
      applyTime,
      type,
    },
    speakers,
    doctors,
  }) =>
    `${id}-${startTime}-${endTime}-${cnName}-${name}-${applyTime}-${doctors?.length}-${speakers?.length}`

  format = (ret) => {
    const res = {
      list: ret.data.items,
      nomore:
        ret.data.pager.currentPage >= Math.ceil(ret.data.pager.total / 20),
    }
    return res
  }

  onItemClick = (meetingInfo) => {
    // 加个判断
    // tofix https://sentry.quancheng-ec.com/sentry/hengrui-radiation-h5-pro/issues/4914/
    if (meetingInfo) {
      // 混合列表 ，区分会议和项目和活动
      const itemType = MEETING_TYPE_MAP[meetingInfo.meetingType]
      const path = itemType?.detailPath
      const params = itemType?.formatParams({ meetingInfo })
      path &&
        this.props.$router.push(path, {
          ...params,
          meetingType: meetingInfo.meetingType,
        })
    }
  }

  renderItem = (item) => (
    // 除了审批中其他都不显示去审批按钮
    <MeetingItem
      {...item}
      showApprovalBtn={this.state.tabIndex === 0}
      onItemClick={this.onItemClick}
      approveTabText={['wait', 'approved'][this.state.tabIndex]}
    />
  )

  renderEmptyView = () => {
    return (
      <EmptyView
        iconName='iconkong'
        iconColor='#eaeaea'
        bgColor='transparent'
        textColor='#ccc'
      />
    )
  }

  onStatusChange = (tabIndex) => {
    this.setState({
      tabIndex,
    })
  }

  render() {
    const { tabIndex } = this.state
    const params = {
      approveStatus: ['wait', 'approved'][tabIndex],
    }
    return (
      <Fragment>
        <ColumnView
          width='100vw'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9,
            boxShadow: '0 2px 5px 0 rgb(0 0 0 / 10%)',
          }}
        >
          <TabsSimple
            index={tabIndex}
            lineHeight={4}
            lineWidth={30}
            height={100}
            size={34}
            items={TAB_OPTIONS}
            color='#999'
            activeColor='#181818'
            bgColor='#fff'
            onChange={this.onStatusChange}
            renderItem={(item, i) => (
              <Text
                color={tabIndex === i ? '#181818' : '#999'}
                size={34}
                style={{ fontWeight: tabIndex === i ? 500 : 400 }}
              >
                {item}
              </Text>
            )}
          />
        </ColumnView>
        <ColumnView padding={[100, 0, 0, 0]}>
          <AutoList
            refreshable
            pageSize={20}
            params={params}
            fetchListData={queryMyApprovingMeetings}
            renderItem={this.renderItem}
            keyExtractor={this.keyExtractor}
            format={this.format}
            // itemClickHandler={this.onItemClick}
            EmptyView={this.renderEmptyView}
            minHeight='70vh'
          />
        </ColumnView>
      </Fragment>
    )
  }
}
