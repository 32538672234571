import { h, Fragment } from 'preact'
import { SlotRowView } from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import Icon from '@ruiyun/preact-icon'
import Line from '@ruiyun/preact-line'

const ItemCell = ({
  label,
  textSize = 30,
  textColor = '#181818',
  icon = '',
  iconColor = '#F4630B',
  value,
  valueColor = '#666666',
  arrow = false,
  line = true,
  onClick,
  padding = [24, 30, 24, 30],
  slot,
}) => (
  <Fragment>
    <SlotRowView
      slot={30}
      padding={padding}
      hAlign='between'
      onClick={() => onClick && onClick()}
      width='100%'
    >
      <SlotRowView slot={15} style={{ flexShrink: 0, alignItems: 'baseline' }}>
        {icon && <Icon name={icon} color={iconColor} />}
        <Text color={textColor} size={textSize}>
          {label}
        </Text>
      </SlotRowView>
      <SlotRowView slot={15} style={{ alignSelf: 'center' }}>
        {value && <Text color={valueColor}>{value}</Text>}
        {arrow && <Icon name='iconJIANTOU-small' size={32} color='#888888' />}
      </SlotRowView>
      {slot && slot()}
    </SlotRowView>
    {line && <Line indent={[30, 30]} scale={1} color='#F9F9FA' />}
  </Fragment>
)

export default ItemCell
