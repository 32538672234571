import { h, Component } from 'preact'
import { getCurrentUserInfo } from '../services/UserService'

export const HR_PRO = window.localStorage.getItem('HR_PRO')

const WithUserInfo = (BaseComponent) => {
  class ComponentWithUserInfo extends Component {
    constructor() {
      super()
      this.fetchProduction = this.fetchProduction.bind(this)
    }

    state = {
      production: HR_PRO,
    }

    async fetchProduction() {
      const { success, data } = await getCurrentUserInfo()
      if (success && data?.hrProduction) {
        this.setState({ production: data.hrProduction }, () => {
          window.localStorage.setItem('HR_PRO', data.hrProduction)
        })
      }
    }

    componentDidMount() {
      const production = window.localStorage.getItem('HR_PRO')
      if (!production) {
        this.fetchProduction()
      } else {
        this.setState({
          production: production,
        })
      }
    }

    render() {
      const { production } = this.state
      return (
        <BaseComponent
          {...this.props}
          $HR_PRO={production}
          $IS_RADIATION={production === 'RADIATION'}
          $IS_HANSOH={production === 'HANSOH'}
          $IS_ANESTHESIOLOGY={production === 'ANESTHESIOLOGY'}
          $IS_CTTQ={production === 'CTTQ'}
          $IS_QILU={production === 'QILU'}
          $IS_ZMHD={production === 'ZMHD'}
        />
      )
    }
  }

  return ComponentWithUserInfo
}

export default WithUserInfo
