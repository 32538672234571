import { h, Component } from 'preact'
import { ColumnView } from '@ruiyun/preact-layout-suite'
import { ScrollerWithRefresh } from '@ruiyun/preact-m-scroller'
import WithRouter from '@xupright/preact-m-router'
import wx from 'weixin-js-sdk'
import p2r from 'p-to-r'

import { queryBanners, queryContent } from '../../services/HomeService'
import { queryMeetingList } from '../../services/MeetingService'
import { queryMyApprovingMeetings } from '../../services/ApprovalService'
import WithAppCategories from '../../libs/appCategories'
import SwiperController from '../../components/SwiperController'
import TabCard from '../../components/TabCard'
import { TodoItem, EntryButtons, ContentItem } from './components'
import { MEETING_TYPE_MAP } from '../../constants/meeting'
import WithUserInfo from '../../libs/withUserInfo'

const todoConfig = {
  options: [
    { name: '待办事', key: 'all' },
    { name: '待审批', key: 'approveWaiting' },
  ],
  style: {
    background: 'linear-gradient(147deg, #E4E0FE 0%, #C1DDFF 100%)',
    marginTop: p2r(10),
  },
}

const contentConfig = {
  options: [
    { name: '合规', key: 'GRC' },
    { name: '视频', key: 'VIDEO' },
    { name: '文献', key: 'DOC' },
    { name: '新闻', key: 'NEWS' },
  ],
  style: { background: 'linear-gradient(141deg, #F8E0FE 0%, #C1DAFF 100%)' },
}

@WithRouter
@WithAppCategories
@WithUserInfo
export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerUrls: [],
      bannerLoading: false,
      contentActive: 0,
      todoActive: 0,
      keyword: 0,
    }
  }

  onEditMeeting = ({ meetingType, id }) => {
    // 现在这一步没用，点编辑也直接进详情页
    if (MEETING_TYPE_MAP[meetingType] && id) {
      this.props.$router.push('applyMeeting', {
        meetingId: id,
        meetingType: meetingType,
      })
    }
  }

  onMeetingItemClick = ({ meetingType, id }) => {
    if (MEETING_TYPE_MAP[meetingType] && id) {
      this.props.$router.push(MEETING_TYPE_MAP[meetingType]?.detailPath, {
        meetingId: id,
        activityId: id,
        projectId: id,
        meetingType: meetingType,
      })
    }
  }

  renderTodo = (item) => (
    <TodoItem
      {...item}
      activeKey={this.state.todoActive}
      onEditMeeting={this.onMeetingItemClick}
      onMeetingItemClick={this.onMeetingItemClick}
    />
  )

  renderContent = (item) => {
    // const { contentActive } = this.state
    return <ContentItem {...item} />
    // return contentActive !== 2 ? (
    //   <a
    //     style={{ textDecoration: 'unset', width: '100%' }}
    //     target='__blank'
    //     href={item?.signedUrl}
    //   >
    //     <ContentItem {...item} />
    //   </a>
    // ) : (
    //   <ContentItem {...item} />
    // )
  }

  onEntryClick = (code) => {
    MEETING_TYPE_MAP[code] &&
      this.props.$router.push(MEETING_TYPE_MAP[code].applyPath, {
        meetingType: code,
      })
  }

  queryBanners = async (showLoading = true) => {
    const { success, data } = await queryBanners(showLoading)
    if (success) {
      const urls = data.map((item) => item.signedUrl)
      this.setState({
        bannerUrls: urls,
      })
    }
    this.setState({ bannerLoading: false })
  }

  onRefresh = (done) => {
    setTimeout(() => {
      this.props.$fetchCategories()
      this.queryBanners(false).then(done)
      this.setState({ keyword: this.state.keyword === 0 ? 1 : 0 })
    }, 300)
  }

  // 筛掉预注册
  format = (data) =>
    data?.items?.filter(
      (i) => i?.meetingInfo?.meetingType !== 'PRE_REGISTERED_PROJECT'
    ) || []

  componentDidMount() {
    this.props.$router.setTitle('首页')
    this.setState({ bannerLoading: true })
    this.props.$fetchCategories()
    this.queryBanners()
  }

  render() {
    const { bannerUrls, bannerLoading, todoActive, contentActive, keyword } =
      this.state
    const contentParams = {
      type: contentConfig.options[contentActive].key,
      ...(keyword === 0 ? {} : { keyword: '' }),
    }
    const todoListFetch =
      todoActive === 0 ? queryMeetingList : queryMyApprovingMeetings
    const todoParams =
      todoActive === 0
        ? {
            status: ['MEETING_IN_PROGRESS', 'MEETING_RETREATED'],
            filter: 'mine',
            ...(keyword === 0 ? {} : { keyword: '' }), // 'keyword'只是为了下拉刷新时重新请求列表
          }
        : {
            approveStatus: 'wait',
            ...(keyword === 0 ? {} : { keyword: '' }),
          }

    return (
      <ScrollerWithRefresh onRefresh={this.onRefresh} minHeight='70vh'>
        <ColumnView height='100%' bgColor='#fff' padding={[0, 0, 60, 0]}>
          {bannerUrls?.length > 0 && (
            <SwiperController urls={bannerUrls} loading={bannerLoading} />
          )}

          {/* 豪森推广活动禁止申请  */}
          <EntryButtons
            entries={
              this.props.$IS_HANSOH
                ? this.props.$CATEGORIES?.filter(
                    (i) => i.code !== 'ADVERTISING_ACTIVITIES'
                  )
                : this.props.$CATEGORIES
            }
            onEntryClick={this.onEntryClick}
          />

          <TabCard
            onMoreClick={() => {
              this.props.$router.push(
                todoActive === 0 ? 'homeList' : 'approvalList'
              )
            }}
            options={todoConfig.options}
            style={todoConfig.style}
            activeColor='#A799F9'
            fetchListData={todoListFetch}
            params={{ ...todoParams, pageSize: 3, pageNum: 1 }}
            onTabChange={(index) => this.setState({ todoActive: index })}
            renderItem={this.renderTodo}
            formatData={this.format}
          />

          <TabCard
            onMoreClick={() => this.props.$router.push('contentCenter')}
            options={contentConfig.options}
            activeColor='#A799F9'
            style={contentConfig.style}
            fetchListData={queryContent}
            params={contentParams}
            onTabChange={(index) => this.setState({ contentActive: index })}
            renderItem={this.renderContent}
            onItemClick={(item) => {
              if (contentActive === 2) {
                this.props.$router.push('contentDetail', {
                  archiveId: item.id,
                })
              } else {
                const url = item?.signedUrl
                if (url) {
                  console.log(url)
                  const sliceIndex = url.lastIndexOf('.')
                  const type = url.slice(sliceIndex + 1)

                  const params = { filePath: url, fileType: type }
                  wx.miniProgram.navigateTo({
                    url: `/pages/preview/index?params=${encodeURIComponent(
                      JSON.stringify(params)
                    )}`,
                  })
                }
              }
            }}
          />
        </ColumnView>
      </ScrollerWithRefresh>
    )
  }
}
