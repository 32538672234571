import { h, Component, toChildArray, cloneElement } from 'preact'
import { RowView, SlotColumnView } from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import Icon from '@ruiyun/preact-icon'
import Page from '@ruiyun/preact-m-page'
import { ScrollerWithPreventBounce } from '@ruiyun/preact-m-scroller'
import p2r from 'p-to-r'

import ForkPageFooter from '../../components/ForkPageFooter'
import classNames from './index.css'

class Item extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.show) {
      // 对于已经render出来的item
      // 择机调用它们的tabOnShow和tabOnHide钩子
      const { onTabShow, onTabHide } = this.itemRef || {}
      let handler
      if (nextProps.show) {
        handler = onTabShow
      } else if (this.props.show) {
        handler = onTabHide
      }
      if (handler) {
        setTimeout(handler, 0)
      }
    }
    // 切换已经出现的tab不需要重新rerender子元素
    // 只有首次加载才需要render
    return nextProps.show && !this.show
  }

  render() {
    const { children, show, index } = this.props
    if (show) {
      this.show = show
    }
    const currentClassName = `fork_tab_item_scroll_${index}`

    return this.show ? (
      <ScrollerWithPreventBounce
        height={`calc(100% - ${p2r(0)})`}
        key={currentClassName}
        id={currentClassName}
      >
        {cloneElement(children, {
          ref: (s) => {
            this.itemRef = s
          },
        })}
      </ScrollerWithPreventBounce>
    ) : undefined
  }
}

export default class ForkTabBar extends Component {
  state = {
    index: 0,
  }

  onSwitch = (index, item) => {
    this.setState({
      index,
    })
    item.onSwitch && item.onSwitch()
  }

  render() {
    const { index } = this.state
    const {
      children,
      config: {
        options,
        iconColor,
        textColor,
        textStyle,
        activeIconColor,
        activeTextColor,
        activeTextStyle,
        textSize,
        iconSize,
        barHeight = 100,
        padding = [0, 50, 0, 50],
        slot = 2,
        bgColor = '#fff',
      },
    } = this.props
    const childrenArr = toChildArray(children)
    return (
      <Page>
        {childrenArr.map((child, key) => (
          <div
            key={key}
            className={`${key === index ? classNames.show : classNames.hide}`}
          >
            <Item show={key === index} index={index}>
              {child}
            </Item>
          </div>
        ))}

        <ForkPageFooter>
          <RowView
            width='100%'
            hAlign='between'
            padding={padding}
            bgColor={bgColor}
            height={barHeight}
          >
            {options.map((item, i) => (
              <SlotColumnView
                slot={slot}
                hAlign='center'
                onClick={() => this.onSwitch(i, item)}
                key={item.text}
              >
                <Icon
                  name={item.icon}
                  size={iconSize}
                  color={index === i ? activeIconColor : iconColor}
                />
                <Text
                  size={textSize}
                  color={index === i ? activeTextColor : textColor}
                  style={index === i ? activeTextStyle : textStyle}
                >
                  {item.text}
                </Text>
              </SlotColumnView>
            ))}
          </RowView>
        </ForkPageFooter>
      </Page>
    )
  }
}
