import { h, Component } from 'preact'
import WithRouter, { router } from '@xupright/preact-m-router'

import Home from './home'
import User from './user'
import ForkTabBar from '../../components/ForkTabBar'
import { getCurrentUserInfo } from '../../services/UserService'
import { TOKEN_KEY } from '../../constants/other'
import { parse } from '../../libs/parse'
import ApprovalEntry from '../approvalList/app'
import HomeList from '../homeList/app'

const tabBarConfig = {
  options: [
    {
      text: '首页',
      icon: 'iconSHOUYE',
      onSwitch: () => {
        router.setTitle('首页')
      },
    },
    {
      text: '项目',
      icon: 'iconxiangmu',
      onSwitch: () => {
        router.setTitle('我的项目')
      },
    },
    {
      text: '审批',
      icon: 'iconshenpi',
      onSwitch: () => {
        router.setTitle('审批列表')
      },
    },
    {
      text: '我的',
      icon: 'iconWODE',
      onSwitch: () => {
        router.setTitle('个人中心')
      },
    },
  ],
  textSize: 24,
  iconSize: 40,
  iconColor: '#BABDC7',
  textColor: '#888',
  textStyle: { fontWeight: 400 },
  activeIconColor: '#5581FA',
  activeTextColor: '#181818',
  activeTextStyle: { fontWeight: 500 },
}

@WithRouter
export default class Index extends Component {
  constructor(props) {
    super(props)
    this.urlParams = props.$router.params || {}
    window.localStorage.setItem(
      TOKEN_KEY,
      decodeURIComponent(
        this.urlParams.token || parse(window.location.search)?.token || ''
      )
    )
  }

  async componentDidMount() {
    const { success, data } = await getCurrentUserInfo(false)
    if (success) {
      window.localStorage.setItem('HR_PRO', data.hrProduction)
    }
  }

  render() {
    return (
      <ForkTabBar config={tabBarConfig}>
        <Home />
        <HomeList />
        <ApprovalEntry />
        <User />
      </ForkTabBar>
    )
  }
}
