import { h, Component } from 'preact'
import sortBy from 'lodash/sortBy'
import { queryAppCategories } from '../services/HomeService'

const APP_CATEGORIES = window.localStorage.getItem('APP_CATEGORIES')

const WithAppCategories = (BaseComponent) => {
  class ComponentWithAppCategories extends Component {
    constructor() {
      super()
      this.fetchCategories = this.fetchCategories.bind(this)
    }

    state = {
      categories: APP_CATEGORIES ? JSON.parse(APP_CATEGORIES) : [],
    }

    async fetchCategories() {
      const { data } = await queryAppCategories()
      const formatData = data
        ?.filter((k) => !!k.enable)
        ?.map((i) => {
          return {
            title: i.config?.title,
            code: i.config?.code,
            enable: i.enable,
            id: i.config?.id,
            sort: i.sort || i.config?.sort,
            activityProperties:
              i.activityProperties && JSON.parse(i.activityProperties),
            // mySettled: i.mySettled,
            // myApplied: i.myApplied,
          }
        })
      const sortData = sortBy(formatData, (o) => o.sort)
      this.setState(
        {
          categories: sortData,
        },
        () => {
          window.localStorage.setItem(
            'APP_CATEGORIES',
            JSON.stringify(sortData)
          )
        }
      )
    }

    componentDidMount() {
      const categories = window.localStorage.getItem('APP_CATEGORIES')
      if (!categories) {
        this.fetchCategories()
      } else {
        this.setState({
          categories: JSON.parse(categories),
        })
      }
    }

    render() {
      return (
        <BaseComponent
          {...this.props}
          $CATEGORIES={this.state.categories}
          $fetchCategories={this.fetchCategories}
        />
      )
    }
  }

  return ComponentWithAppCategories
}

export default WithAppCategories
