// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loadingContainer__2OqPH{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.1)}", ""]);
// Exports
exports.locals = {
	"loadingContainer": "loadingContainer__2OqPH"
};
module.exports = exports;
