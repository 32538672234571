import {
  QUERY_BANNERS,
  QUERY_CONTENT,
  QUERY_CONTENT_WITH_PAGER,
  APP_CATEGORIES,
  APP_CATEGORY_PROPS,
} from '../constants/home.gql'
import { GraphQLClient } from '../libs/request'

export const queryBanners = async (showLoading) =>
  GraphQLClient.gql(QUERY_BANNERS, {}, showLoading)

export const queryContent = (params) =>
  GraphQLClient.gql(QUERY_CONTENT, params, false)

export const queryContentWithPager = ({ pageSize, pageNum, type }) =>
  GraphQLClient.gql(
    QUERY_CONTENT_WITH_PAGER,
    {
      type,
      pager: {
        currentPage: pageNum,
        pageSize,
      },
    },
    false
  )

export const queryAppCategories = () => GraphQLClient.gql(APP_CATEGORIES)

export const queryAppCategoryProps = ({ id }) =>
  GraphQLClient.gql(APP_CATEGORY_PROPS, { aid: id })
