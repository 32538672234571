import { h, Component } from 'preact'
import TabsSimple from '@ruiyun/preact-m-tabs-simple'
import { ColumnView, RowView, SlotRowView } from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import p2r from 'p-to-r'
import { TextButton } from '@ruiyun/preact-button'
import Icon from '@ruiyun/preact-icon'
import Loading from '@ruiyun/preact-loading'
import classNames from './index.css'

const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

/**
 * options: tab选项 [{name: '选项1' key: 'tab1'}]
 * activeColor: 选中时下划线的颜色
 * bgColor: 卡片背景色
 * renderItem: 自定义渲染列表item
 * fetchListData: 列表接口
 * params: 接口参数
 * maxShow: 列表最多显示多少个item，默认3个
 * onMoreClick: 更多按钮点击事件
 * onTabChange: tab 切换事件
 */
export default class TabCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      data: [],
      loading: false,
    }
  }

  fetchData = async (showLoading) => {
    showLoading && this.setState({ loading: true })
    const { fetchListData, params, formatData } = this.props
    const { success, data } = await fetchListData(params)
    if (success) {
      this.setState({
        data: formatData ? formatData(data) : data,
        loading: false,
      })
    }
  }

  onTabChange = async (tabIndex) => {
    const { onTabChange } = this.props
    this.setState({
      tabIndex,
    })
    onTabChange && onTabChange(tabIndex)
  }

  componentDidMount() {
    this.fetchData(false)
  }

  componentDidUpdate(prevProps) {
    // params改变了需要重新刷新
    if (!isEqual(prevProps.params, this.props.params)) {
      this.fetchData(true)
    }
  }

  render() {
    const {
      options,
      activeColor = '#F99999',
      renderItem,
      maxShow = 3,
      onMoreClick,
      margin = [20, 30, 20, 30],
      padding = [20, 30, 40, 30],
      style,
      onItemClick,
    } = this.props
    const { tabIndex, data, loading } = this.state
    return (
      <ColumnView
        margin={margin}
        padding={padding}
        style={Object.assign(
          {
            position: 'relative',
            borderRadius: p2r(30),
          },
          style
        )}
      >
        {loading && (
          <ColumnView
            style={{
              borderRadius: p2r(30),
            }}
            className={classNames.loadingContainer}
            hAlign='center'
            padding={[155, 0, 0, 0]}
          >
            <Loading size={50} color='#999' />
          </ColumnView>
        )}

        <TextButton
          onClick={onMoreClick}
          style={{ position: 'absolute', right: p2r(24), top: p2r(34) }}
        >
          <SlotRowView slot={4}>
            <Text
              size={30}
              color={activeColor}
              style={{ marginRight: p2r(-5) }}
            >
              更多
            </Text>
            <Icon
              name='iconJIANTOU-small'
              color={activeColor}
              size={34}
              style={{ marginRight: p2r(-5) }}
            />
          </SlotRowView>
        </TextButton>
        <TabsSimple
          align='left'
          slot={30}
          index={tabIndex}
          lineHeight={6}
          lineWidth={40}
          height={80}
          items={options.map((i) => i.name)}
          renderItem={(item, i, isActive) => (
            <Text
              color={isActive ? '#181818' : '#888'}
              size={isActive ? 38 : 30}
              weight={isActive ? 500 : 400}
            >
              {item}
            </Text>
          )}
          color='#888'
          activeColor={activeColor}
          bgColor='transparent'
          onChange={this.onTabChange}
        />
        {renderItem && data.length ? (
          data.slice(0, maxShow).map((item, index) => (
            <RowView
              width='100%'
              key={index}
              onClick={() => onItemClick && onItemClick(item)}
            >
              {renderItem(item, index)}
            </RowView>
          ))
        ) : (
          <RowView hAlign='center' padding={[60, 0, 60, 0]}>
            <Text color='#888'>暂无数据</Text>
          </RowView>
        )}
      </ColumnView>
    )
  }
}
