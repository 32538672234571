import { h } from 'preact'
import { SlotColumnView } from '@ruiyun/preact-layout-suite'
import Icon from '@ruiyun/preact-icon'
import Text from '@ruiyun/preact-text'

const EmptyView = ({
  padding = [300, 0, 200, 0],
  text = '暂无数据',
  iconName = 'iconkong',
  bgColor = '#fff',
  iconColor = '#e0e0e0',
  textColor = '#999',
  textSize = 26,
} = {}) => (
  <SlotColumnView hAlign='center' slot={30} bgColor={bgColor} padding={padding}>
    <Icon name={iconName} color={iconColor} size={110} />
    <Text size={textSize} color={textColor}>
      {text}
    </Text>
  </SlotColumnView>
)
export default EmptyView
