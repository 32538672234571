// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".borderLeft__1VXeh{border-top-right-radius:0!important;border-bottom-right-radius:0!important;border-right:unset!important}.ellipsis__2fv2f{display:inline-block;white-space:nowrap;position:relative;overflow:hidden;text-overflow:ellipsis}", ""]);
// Exports
exports.locals = {
	"borderLeft": "borderLeft__1VXeh",
	"ellipsis": "ellipsis__2fv2f"
};
module.exports = exports;
