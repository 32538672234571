import { h } from 'preact'
import {
  SlotRowView,
  SlotColumnView,
  XCenterView,
  RowView,
} from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import Icon from '@ruiyun/preact-icon'
import p2r from 'p-to-r'
import dayjs from 'dayjs'

import { Tag } from '../../components/ApplyTimeTag'
import {
  MEETING_STATUS_MAP,
  SETTLEMENT_STATUS_MAP,
  MEETING_STATUS_AFTER_SETTLEMENT_CONFIRMING,
  MEETING_TYPE_MAP,
} from '../../constants/meeting'

import FailedSvg from '../../assets/failed.svg'
import DelaySvg from '../../assets/delay.svg'

const ellipsisStyle = {
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const MeetingItem = ({
  info: {
    id,
    startTime,
    endTime,
    owner: { cnName },
    name,
    applyTime,
    meetingType,
    activityType,
  },
  padding = [30, 30, 30, 30],
  margin = [20, 30, 0, 30],
  speakers,
  hospital,
  status,
  activityType: activityTypeName,
  meetingType: meetingTypeName,
  onItemClick,
  approveTabText,
}) => {
  const settlements = speakers?.map((s) => s?.settlementRecord?.status)

  // 结算确认中之后的会议，展示结算状态
  const showSettlement =
    MEETING_STATUS_AFTER_SETTLEMENT_CONFIRMING.includes(status)

  const showFailedStamp =
    showSettlement &&
    !!settlements.length &&
    settlements.findIndex((i) => i === SETTLEMENT_STATUS_MAP.failed.value) > -1

  const showDelayStamp =
    showSettlement &&
    !!settlements.length &&
    settlements.findIndex((i) => i === SETTLEMENT_STATUS_MAP.delay.value) > -1

  return (
    <SlotColumnView
      bgColor='#ffffff'
      margin={margin}
      padding={padding}
      slot={12}
      style={{ position: 'relative', borderRadius: p2r(30) }}
      onClick={() => {
        onItemClick && onItemClick(id)
      }}
    >
      {(showDelayStamp || showFailedStamp) && (
        // 若同时存在打款失败，打款延迟的speaker，优先显示打款失败标识
        <XCenterView style={{ position: 'absolute', right: 10, top: 40 }}>
          <img
            style={{ width: p2r(130), height: p2r(130) }}
            src={showFailedStamp ? FailedSvg : DelaySvg}
          />
        </XCenterView>
      )}

      <RowView hAlign='between'>
        <Text
          color='#181818'
          size={36}
          style={{
            flex: 1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontWeight: 500,
          }}
        >
          {name}
        </Text>
        {approveTabText ? (
          <SlotRowView slot={4}>
            <Text
              size={26}
              color={approveTabText === 'wait' ? '#FF9847' : '#BABDC7'}
              style={{ marginRight: p2r(-5) }}
            >
              {approveTabText === 'wait' ? '待审核' : '已审核'}
            </Text>
            <Icon
              name='iconJIANTOU-small'
              color={approveTabText === 'wait' ? '#FF9847' : '#BABDC7'}
              size={34}
              style={{ marginRight: p2r(-10) }}
            />
          </SlotRowView>
        ) : (
          <SlotRowView slot={4}>
            <Text
              size={26}
              color={MEETING_STATUS_MAP[status]?.color || '#EE7700FF'}
              style={{ marginRight: p2r(-5) }}
            >
              {MEETING_STATUS_MAP[status]?.name}
            </Text>
            <Icon
              name='iconJIANTOU-small'
              color={MEETING_STATUS_MAP[status]?.color || '#EE7700FF'}
              size={34}
              style={{ marginRight: p2r(-10) }}
            />
          </SlotRowView>
        )}
      </RowView>
      <RowView>
        <Text size={28} color='#666'>
          {cnName}
        </Text>
        <Text
          size={28}
          color='#BABDC7'
          style={{ padding: `0 ${p2r(15)} 0 ${p2r(15)}` }}
        >
          /
        </Text>
        <Text size={28} color='#666'>
          {dayjs(startTime).format('YYYY.MM.DD')}-
          {dayjs(endTime).format('YYYY.MM.DD')}
        </Text>
      </RowView>
      <RowView style={{ flexWrap: 'wrap' }}>
        {approveTabText && meetingTypeName && meetingType && (
          <Tag
            margin={[0, 10, 10, 0]}
            padding={[0, 10, 0, 10]}
            style={{ maxWidth: '40%', borderRadius: p2r(8) }}
            textStyle={ellipsisStyle}
            text={meetingTypeName}
            fontSize={20}
            bgColor={MEETING_TYPE_MAP[meetingType].typeBgColor || '#388CEE'}
            fontColor={MEETING_TYPE_MAP[meetingType].typeFontColor || '#fff'}
          />
        )}
        {!approveTabText &&
          meetingType === 'ACADEMIC_CONFERENCE' &&
          activityTypeName &&
          activityType && (
            <Tag
              margin={[0, 10, 10, 0]}
              padding={[0, 10, 0, 10]}
              style={{ maxWidth: '40%', borderRadius: p2r(8) }}
              textStyle={ellipsisStyle}
              text={
                activityType === 'LIVE_ONLINE'
                  ? '线上'
                  : activityType === 'OFFLINE_MEETING'
                  ? '线下'
                  : activityTypeName
              }
              fontSize={20}
              bgColor={
                activityType === 'LIVE_ONLINE'
                  ? '#83C7FF'
                  : activityType === 'OFFLINE_MEETING'
                  ? '#FC8383'
                  : '#83C7FF'
              }
              fontColor='#fff'
            />
          )}
        {applyTime && (
          <Tag
            margin={[0, 10, 10, 0]}
            padding={[0, 0, 0, 0]}
            text={`申请时间：${dayjs(applyTime).format('YYYY.MM.DD')}`}
            fontColor='#BABDC7'
            bgColor='#fff'
            fontSize={28}
          />
        )}
      </RowView>
    </SlotColumnView>
  )
}

export default MeetingItem
