import Ajax from '@ruiyun/ajax'
import {
  CREATE_MEETING,
  QUERY_MEETING_LIST,
  QUERY_MINE_MEETING_LIST,
  QUERY_MEETING_DETAIL,
  QUERY_MEETING_COUNT,
  UPDATE_MEETING_SPEAKER_MATERIALS,
  ADD_MEETING_COST,
  SUBMIT_MEETING,
  CONFIRM_MEETING,
  RETREAT_MEETING,
  UPDATE_MEETING,
  UPDATE_MEETING_SCHEDULES,
  QUERY_MEETING_VENDORS,
  PRE_REGISTER_PTXUSER,
  QUERY_INTER_PROJECT_TYPES,
  QUERY_MEETING_ADDRESS,
  DELETE_MEETING,
  GENERATE_INVITATION_SIGNATURE,
  FETCH_INVITATION_DATA_BY_SIGNATURE,
  QUERY_ACTIVITY_TYPES_WITH_MEETING_TYPE,
  QUERY_MEETING_APPROVAL_FLOWS,
  GET_URL_LINK,
  GET_APP_URL,
  QUERY_COMPANY_PRODUCTS,
  QUERY_CONFERENCE_PLATFORM,
  QUERY_ACADEMIC_CONFERENCE_CATEGORIES,
  RECALL_APPROVAL_MEETING,
} from '../constants/meeting.gql'
import { PROCESS_URL } from '../constants/apis'

import { GraphQLClient } from '../libs/request'
export const createMeeting = (meetingInfo) =>
  GraphQLClient.gql(CREATE_MEETING, { meetingInfo })
export const updateMeeting = (meetingInfo) =>
  GraphQLClient.gql(UPDATE_MEETING, { meetingInfo })
export const queryMeetingList = (params) =>
  GraphQLClient.gql(QUERY_MEETING_LIST, params, false, 'all')
export const queryMineMeetingList = () =>
  GraphQLClient.gql(QUERY_MINE_MEETING_LIST)
export const queryMeetingDetail = (meetingId, showLoading) =>
  GraphQLClient.gql(QUERY_MEETING_DETAIL, { meetingId }, showLoading)
export const queryMeetingCount = (showLoading) =>
  GraphQLClient.gql(QUERY_MEETING_COUNT, {}, showLoading)
export const addMeetingCost = (params) =>
  GraphQLClient.gql(ADD_MEETING_COST, params)
export const updateSpeakerMaterials = ({
  meetingId,
  doctorId,
  materials,
  frontPicUrls,
  panoramicPicUrls,
}) =>
  GraphQLClient.gql(UPDATE_MEETING_SPEAKER_MATERIALS, {
    meetingId,
    doctorId,
    materials,
    frontPicUrls,
    panoramicPicUrls,
  })
export const updateSchedules = ({ meetingId, schedulePicUrls, signPicUrls }) =>
  GraphQLClient.gql(UPDATE_MEETING_SCHEDULES, {
    meetingId,
    schedulePicUrls,
    signPicUrls,
  })
export const submitMeeting = ({ meetingId }) =>
  GraphQLClient.gql(SUBMIT_MEETING, { meetingId })
export const confirmMeeting = ({ meetingId }) =>
  GraphQLClient.gql(CONFIRM_MEETING, { meetingId })
export const retreatMeeting = ({ meetingId, reason }) =>
  GraphQLClient.gql(RETREAT_MEETING, { meetingId, reason })
export const recallApprovalMeeting = ({ meetingId, reason }) =>
  GraphQLClient.gql(RECALL_APPROVAL_MEETING, { meetingId, reason })

export const queryMeetingVendors = () =>
  GraphQLClient.gql(QUERY_MEETING_VENDORS, {}, false)

export const registerPTXUser = (params) =>
  GraphQLClient.gql(PRE_REGISTER_PTXUSER, params)

export const queryInterProjectTypes = () =>
  GraphQLClient.gql(QUERY_INTER_PROJECT_TYPES, {}, false)

export const queryMeetingAddress = ({ meetingId }) =>
  GraphQLClient.gql(QUERY_MEETING_ADDRESS, { meetingId })

export const getApprovalProcess = async (meetingId) => {
  console.log(meetingId)
  let response = {
    success: false,
  }
  try {
    const ret = await Ajax.post(
      `${PROCESS_URL}project/getApproveDetail?projectId=${meetingId}`,
      {
        headers: {
          loading: 'false',
        },
      }
    )
    if (ret && ret.success) {
      response = {
        success: true,
        data: ret.result,
      }
    } else {
      response = {
        success: false,
        message: ret.message,
      }
    }
  } catch (error) {
    response.error = error
  }
  return response
}

export const deleteMeeting = ({ meetingId }) =>
  GraphQLClient.gql(
    DELETE_MEETING,
    {
      meetingId,
    },
    true
  )

export const generateInvitationSignature = ({ expire, snapshot }) =>
  GraphQLClient.gql(
    GENERATE_INVITATION_SIGNATURE,
    {
      expire,
      snapshot,
    },
    true
  )

export const fetchInvitationData = ({ signature }) =>
  GraphQLClient.gql(FETCH_INVITATION_DATA_BY_SIGNATURE, { signature }, true)

export const queryActivityTypesWithMeetingType = ({ meetingType }) =>
  GraphQLClient.gql(QUERY_ACTIVITY_TYPES_WITH_MEETING_TYPE, {
    meetingType,
    mine: true,
  })

export const queryApprovalFlows = (meetingId) =>
  GraphQLClient.gql(QUERY_MEETING_APPROVAL_FLOWS, { meetingId })

export const getUrlLink = ({ appName, doctorAccountId }) =>
  GraphQLClient.gql(GET_URL_LINK, { appName, doctorAccountId })

export const getAppUrl = ({ path, doctorAccountId }) =>
  GraphQLClient.gql(GET_APP_URL, { path, doctorAccountId })

export const queryCompanyProducts = ({ currentUser }) =>
  GraphQLClient.gql(QUERY_COMPANY_PRODUCTS, { currentUser }, false)

export const queryConferencePlatform = () =>
  GraphQLClient.gql(QUERY_CONFERENCE_PLATFORM)

export const queryAcademicConferenceCategories = () =>
  GraphQLClient.gql(QUERY_ACADEMIC_CONFERENCE_CATEGORIES)
