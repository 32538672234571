/* eslint-disable max-lines */
export const QUERY_MEETING_LIST = `
query($status: [StandardMeetingServiceBizStatus!], $pageSize: Int, $pageNum: Int, $keyword: String, $filter: String, $meetingType: String) {
  result: hengruiImage_meetingList(
    filter: $filter
    pageQuery: {
      query: {
        serviceBizStatus: $status
        meetingType: $meetingType
        keyword: $keyword
      }
      pager: {
        pageSize: $pageSize,
        currentPage: $pageNum
      }
    }) {
    items{
      meetingType: hengruiImage_categoryName
      activityType: hengruiImage_activityType
      approveStatus: hengruiImage_approveStatus
      speakers: hengruiImage_spearkers {
        attenderId
        settlementRecord
      }
      status: hengruiImage_status
      hospital: hengruiImage_hospital
      meetingInfo: meetingBaseData{
        activityConfig
        activityConfigModified {
          id
          instanceMin
          instanceMax
          templateInfo{
            unitPrice
            templateCode
          }
        }
        meetingType: type
        activityType
        id
        peopleNum
        startTime
        applyTime
        endTime
        name
        owner{
          cnName
        }
      }
    }
    pager {
      currentPage
      pageSize
      total
    }
  }
}
`

// 会议 ---- 提交审核
export const SUBMIT_MEETING = `
mutation submitMeeting($meetingId: String!) {
  result: hengruiImage_submitMeeting(meetingId: $meetingId)
}
`
// 会议 ---- 通过
export const CONFIRM_MEETING = `
mutation meetingConfirm($meetingId: String!) {
  result: hengruiImage_confirmMeeting(meetingId: $meetingId)
}
`
// 会议 ---- 退回
export const RETREAT_MEETING = `
mutation meetingRetreat($meetingId: String!, $reason: String) {
  result: hengruiImage_retreatMeeting(meetingId: $meetingId, reason: $reason)
}
`
// 会议 ---- 撤回审批
export const RECALL_APPROVAL_MEETING = `
mutation meetingWithdraw($meetingId: String!, $reason: String) {
  result: meetingWithdraw(meetingId: $meetingId, reason: $reason)
}
`

export const CREATE_MEETING = `
mutation createMeeting($meetingInfo: CreateHengruiMeeting!){
  result: hengruiImage_createMeeting(meetingInfo:$meetingInfo){
    meetingInfo: meetingBaseData{
      id
    }
    isOwner
  }
}`
// 编辑会议
export const UPDATE_MEETING = `
mutation ($meetingInfo: UpdateHengruiMeeting!){
  result: hengruiImage_updateMeeting(meetingInfo:$meetingInfo){
    meetingInfo: meetingBaseData{
      id
    }
  }
}
`

export const QUERY_MY_APPROVING_MEETINGS = `
query($pageQuery: PageQueryStandardMeetingInput, $approveStatus: String) {
  result: hengruiImage_myApprovals(pageQuery: $pageQuery, approveStatus: $approveStatus) {
    items{
      meetingType: hengruiImage_categoryName
      activityType: hengruiImage_activityType
      hospital: hengruiImage_hospital
      speakers: hengruiImage_spearkers{
        attenderId
      }
      doctors: hengruiImage_attendDoctors{
        attenderName
      }
      meetingInfo: meetingBaseData{
        activityConfig
        activityConfigModified {
          id
          instanceMin
          instanceMax
          templateInfo{
            unitPrice
            templateCode
          }
        }
        meetingType: type
        activityType
        id
        peopleNum
        applyTime
        startTime
        endTime
        name
        owner{
          cnName
        }
      }
    }
    pager {
      currentPage
      pageSize
      total
    }
  }
}
`

export const QUERY_MINE_MEETING_LIST = `
query{
  result: hengruiImage_meetingList(filter:"mine"){
    items{
      info: meetingBaseData{
        type
        status
      }
    }
  }
}
`

export const QUERY_MEETING_DETAIL = `
query($meetingId: String) {
  result: hengruiImage_meetingDetail(meetingId: $meetingId) {
    otherProperties{
      value
      diyType
    }
    meetingType: hengruiImage_categoryName
    activityType: hengruiImage_activityType
    isOwner
    isCurrentApprover
    address: hengruiImage_address
    approvalStatus: hengruiImage_approveStatus
    approveReason: hengruiImage_approveReason
    hospital: hengruiImage_hospital
    city: hengruiImage_city{
      name
      id
    }
    statusExtra: hengruiImage_statusExtra
    hengruiImage_status
  	costcenter: hengruiImage_costcenter{
      id
      name
    }
    hengruiImage_spearkers {
      id
      settlementRecord
      attenderId
      attenderName
      attenderPhone
      attenderType
      attenderData
      attenderStatus
      attenderFee
      signChannel: attenderSignChannel
      qkkStudioOpenStatus
      attenderInfo{
        custom1
        department: group(groupType: "department"){
          name
        }
        hospital{
          name
        }
        title: group(groupType: "title"){
          name
        }
        shangshuibaoRegistered
        hasCert: hasCompeleteHRImgDoctorCert(meetingId: $meetingId)
      }
      invitationStatus: invitationButtonStatus
      ptxUserRegistered: ptxUserRegistered
    }
    hengruiImage_costs {
      amount
      type
      note
    }
    schedulePics: hengruiImage_schedulePics
    signPics: hengruiImage_signPics
    meetingBaseData {
      belongProduct {
        id
        productName
        productCode
      }
      activityConfig
      activityConfigModified {
        id
        instanceMin
        instanceMax
        templateInfo{
          unitPrice
          templateCode
        }
      }
      meetingType: type
      activityType
      needBudget
      budgetChannel
      budgetNumber
      code
      id
      name
      topic
      desc
      status
      peopleNum
      applyTime
      startTime
      endTime
      owner {
        cnName
        accountId
        companyMobile
      }
    }
    vendor: hengruiImage_vendor{
      cnName
    }
    approveUser: hengruiImage_meetingApproveUser
  }
}
`

export const QUERY_MEETING_COUNT = `
query {
  result: hengruiImage_meetingCount
}
`
export const UPDATE_MEETING_SPEAKER_MATERIALS = `
mutation updateSpeakerMaterials(
  $frontPicUrls: [String!]
  $panoramicPicUrls: [String!]
  $meetingId: String!
  $materials: [String!]!
  $doctorId: String!
) {
  result: hengruiImage_uploadSpeakerMaterials(
    frontPicUrls:$frontPicUrls
    panoramicPicUrls:$panoramicPicUrls
    meetingId: $meetingId
    materials: $materials
    attenderId: $doctorId
  )
}

`
export const UPDATE_MEETING_SCHEDULES = `
mutation($signPicUrls: [String!]!,$schedulePicUrls: [String!]!, $meetingId: String!) {
  result: hengruiImage_uploadSchedulePics(
    schedulePicUrls: $schedulePicUrls
    signPicUrls: $signPicUrls
    meetingId: $meetingId
  )
}
`
export const ADD_MEETING_COST = `
mutation ($costs: [SaveStandardMeetingCostInput!]!, $meetingId: String!) {
  result: hengruiImage_addCost(costs: $costs, meetingId: $meetingId)
}
`

export const QUERY_MEETING_VENDORS = `
query{
  result: hengruiImage_vendors{
    companyId
    name
    cnName
    ownerId
    status
  }
}
`
export const PRE_REGISTER_PTXUSER = `mutation($idNo:String!, $attenderId: String!){
result: preregisterPTXUser(idNo:$idNo, attenderId:$attenderId ) {
message
success

}
}`

export const QUERY_INTER_PROJECT_TYPES = `
query{
  result: hengruiImage_interProjectType
}
`

export const QUERY_MEETING_ADDRESS = `
query($meetingId: String) {
  result: hengruiImage_meetingDetail(meetingId: $meetingId) {
    address: hengruiImage_address
    city: hengruiImage_city{
      name
      id
    }
  }
}
`
export const DELETE_MEETING = `mutation($meetingId: String!){
result: hengruiImage_deleteMeeting(meetingId:$meetingId){
  errorCode
  message
  success
}
}`

export const GENERATE_INVITATION_SIGNATURE = `mutation($expire:Int,$snapshot:JSON){
  result: generateInvitationSignature(expire:$expire,snapshot:$snapshot )
}`

export const FETCH_INVITATION_DATA_BY_SIGNATURE = `query($signature:String!){
  result: fetchInvitationDataBySignature(signature:$signature)
}`

export const QUERY_ACTIVITY_TYPES_WITH_MEETING_TYPE = `
query($meetingType: String,$mine: Boolean){
  result: appMeetingActivityTypes(meetingType: $meetingType, mine:$mine){
    id
    enable
    instanceMin
    instanceMax
    templateInfo{
      unitPrice
    }
    config{
      sort
      id
      icon
      title
      code
    }
    start
    end
  }
}
`
export const QUERY_MEETING_APPROVAL_FLOWS = `
query($meetingId: String!){
result: queryMeetingApprovalFlows(meetingId: $meetingId){
  instanceId
  status
  reason
  tasks {
    createTime
    status
    opinion
    approverInfo {
      cnName
      accountId
      roleAuthoritiesIncludeQcxs
    }
    logDatas {
      createTime
      logType
      assginer
      assginerInfo {
        cnName
      }
      reason
    }
  }
}
}
`

export const GET_URL_LINK = `
query($appName: String!,$doctorAccountId: String!){
result: getWechatUrlLink(appName: $appName, doctorAccountId:$doctorAccountId)
}
`
export const GET_APP_URL = `
query($path: String!,$doctorAccountId: String!){
result: queryMedicalLiteratureAppUrl(path: $path,doctorAccountId:$doctorAccountId)
}
`

export const QUERY_COMPANY_PRODUCTS = `
query($currentUser:Boolean){
result: companyUpProducts(currentUser: $currentUser) {
  id
  productName
  productCode
  productCategory
  companyId
}
}
`

export const QUERY_CONFERENCE_PLATFORM = `query{result: hengruiImage_conferencePlatforms}`

export const QUERY_ACADEMIC_CONFERENCE_CATEGORIES = `
query{
  result:academicConferenceCategorys
}
`
