export const QUERY_BANNERS = `query {
  result: appBanner {
    id
    title
    url
    source
    thumb
    reviewed
    signedUrl
  }
}
`

// 首页合规等内容
export const QUERY_CONTENT = `query($type: AppInfoType!) {
  result: appInfos(type: $type) {
    previewUrl
    id
    title
    url
    source
    thumb
    reviewed
    signedUrl
    createTimestamp
  }
}`

export const QUERY_CONTENT_WITH_PAGER = `query($type: AppInfoType!, $pager: PagerInput!) {
  result: appInfosWithPager(type: $type, pager: $pager) {
    items {
      previewUrl
      id
      title
      url
      source
      thumb
      reviewed
      signedUrl
      createTimestamp
    }
    pager {
      currentPage
      total
      hasMore
    }
  }
}`

export const APP_CATEGORIES = `
query {
  result: meetingCategories{
  id
  activityProperties
  sort
  enable
  config{
    id
    sort
    title
    code
  }
  }
}
`

export const APP_CATEGORY_PROPS = `
  query($aid: String!) {
    result: appMeetingActivityProps(aid: $aid)
  }
`
