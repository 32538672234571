// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".show__U3MMF{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;overflow:hidden;height:100%}.hide__fXweJ{display:none}", ""]);
// Exports
exports.locals = {
	"show": "show__U3MMF",
	"hide": "hide__fXweJ"
};
module.exports = exports;
