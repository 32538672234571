/**
 * 基于 @ruiyun Page.Footer 的优化，增加了安全区域的背景色: bgColor
 * @ruiyun Page.Footer 底部的安全区域不能设置背景色，造成安全区域的背景色镂空
 **/
import { h, Component } from 'preact'
import classNames from './index.css'

const preventTouchMove = (event) => event.preventDefault()

export default class ForkPageFooter extends Component {
  render() {
    const { children, shadow = true, touchmove, bgColor = '#fff' } = this.props

    const classArr = [classNames.safeBottom]
    if (shadow) {
      classArr.push(classNames.shadowTop)
    }
    return (
      <div
        className={classArr.join(' ')}
        onTouchMove={touchmove || preventTouchMove}
      >
        {children}
        <div
          className={classNames.footerPlaceHolder}
          style={{ backgroundColor: bgColor }}
        />
      </div>
    )
  }
}
